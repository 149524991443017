import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import img1 from "../../assests/images/about-us.jpeg"
import img2 from "../../assests/images/admisstion.png"
import img3 from "../../assests/images/img.png"
import img4 from "../../assests/images/img2.png"
import Welcome from "./welcomeSection/Welcome";
import './landing-page.css'
const Card = [
  {
    title: "HTML",
    imgUrl: img4

  },
  {
    title: "HTML",
        imgUrl: img3
  },
  {
    title: "HTML",
    imgUrl: img1
  },
  {
    title: "HTML",
    imgUrl: img2
  },
];
const LandingPage = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,

    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div style={{backgroundColor:"#ebf2fc"}}>
    <Carousel
      responsive={responsive}
      infinite={true}
        autoPlay={true}
        
    >
        {Card.map((item, index) => {
          return <img src={item.imgUrl} alt="no" className="images"/>;
        })}
      </Carousel>
      {/* <Feature /> */}
      <Welcome />
      
      </div>
  );
};

export default LandingPage;
