import React from 'react'
import "./Under.css"
import ImgU from "../../assests/images/construction.jpeg"
function Under() {
  return (
      <>
          <div className='under'>
              <img src={ImgU} alt="img" height={300}/>
          </div>
      </>
  )
}

export default Under