import React from 'react'
import NotF from "../../assests/images/notfound.gif"
function NotFound() {
  return (
      <div style={{display:"flex",alignItems:"center",justifyContent:"center",height:"60vh",width:"100%"}}>
          <img src={NotF} alt="page not found" />
    </div>
  )
}

export default NotFound