import * as React from "react";
import Box from "@mui/material/Box";
import Logo from "../../assests/images/wLogo.jpeg";
import Grid from "@mui/material/Grid";
import "./header.css";
import { useState, useEffect } from "react";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import Links from "./Links/Links";
import { Button, Backdrop, CircularProgress } from "@mui/material";
import { AiOutlineMenu } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import ContactDetails from "./contact/ContactDetails";
import News from "../Landing-Page/news/News"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  Typography,
} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Header = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isMiniMobile, setIsMiniMobile] = useState(false);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [pathName, setPathName] = useState(location.pathname);
  const [openLoa, setOpenLoa] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const handleClickOpen = () => {
    setOpenMessage(true);
  };

  const handleCloseOpen = () => {
    setOpenMessage(false);
  };

  useEffect(() => {
    setPathName(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 968);
      setIsMiniMobile(window.innerWidth < 476);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setOpen(!open);
    setOpenLoa(true);
    setTimeout(() => {
      setOpenLoa(false);
    }, 600);
  };
  const handleClose = () => {
    console.log("close");
    setOpen(false);
  };

  window.onscroll = function () {
    let currentScrollPos = window.pageYOffset;
    let logo = document.getElementById("wLogo");
    if (currentScrollPos >= 100) {
      logo.style.height = "80px";
      logo.style.transition = "ease 0.5s";
    } else {
      logo.style.height = "100px";
      logo.style.transition = "ease 0.5s";
    }
  };

  return (
    <>
      <div >
        <Dialog
          open={openMessage}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseOpen}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"message"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              feature coming soon
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseOpen}>ok</Button>
          </DialogActions>
        </Dialog>
      </div>
      <Backdrop
        sx={{ color: "#fff", backgroundColor: "#f5f6f6", zIndex: 9999 }}
        open={openLoa}
        onClick={toggleMenu}
      >
        <CircularProgress style={{ color: "#00afef" }} />
      </Backdrop>

      <Box
        sx={{ flexGrow: 1, backgroundColor: "white" }}
        className="Header"
        id="topContent"
      >
        <Grid container>
          <Grid item xs={12} sm={4}>
            <div className="logoHeader">
              <div
                style={{ display: "flex", gap: "15px", alignItems: "center" }}
              >
                <img id="wLogo" src={Logo} alt="logo" />
                <Typography
                  variant="h5"
                  style={{
                    color: "black",
                    fontWeight: "600",
                    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
                  }}
                >
                  <span style={{ color: "#FF0000" }}>W</span>
                  <span style={{ color: "#FFA500" }}>h</span>
                  <span style={{ color: "blue" }}>i</span>
                  <span style={{ color: "#00FF00" }}>t</span>
                  <span style={{ color: "#EE82EE" }}>
                    e<br />
                  </span>
                  <span style={{ color: "#orange" }}>W</span>
                  <span style={{ color: "#EE82EE" }}>i</span>
                  <span style={{ color: "#FF0000" }}>n</span>
                  <span style={{ color: "#FFA500" }}>g</span>
                  <span style={{ color: "blue" }}>s</span>
                  <br />
                  <span style={{ color: "green" }}>School</span>{" "}
                </Typography>
              </div>
              {isMobile && (
                <AiOutlineMenu
                  style={{ fontSize: "2rem" }}
                  onClick={toggleMenu}
                />
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={8}>
            {!isMobile && (
              <Grid item xs={12}>
                <ContactDetails
                  handleClickOpen={handleClickOpen}
                  isMiniMobile={isMiniMobile}
                  isMobile={isMobile}
                />
              </Grid>
            )}
            {!isMobile && (
              <Grid item xs={12} sm={12} className="links">
                <Links
                  isMobile={isMobile}
                  isMiniMobile={isMiniMobile}
                  open={open}
                  toggleMenu={toggleMenu}
                  handleClose={handleClose}
                />
              </Grid>
            )}
          </Grid>
          {isMobile && (
              <>
              <div
                className={`menu ${open ? "open" : ""}`}
                style={{ backgroundColor: "white",zIndex:9999 }}
              >
                <div className="closeBtn">
                  <Button onClick={handleClose}>
                    <BsFillArrowRightCircleFill
                      style={{ color: "black", fontSize: "2rem" }}
                    />
                  </Button>
                </div>
                <div className="closeBottom">
                  <Links toggleMenu={toggleMenu} />
                </div>
                </div>
                </>
                )}
          {(isMiniMobile || isMobile) && (
            <Grid item xs={12}>
              <ContactDetails
                handleClickOpen={handleClickOpen}
                isMiniMobile={isMiniMobile}
                isMobile={isMobile}
              />
            </Grid>
          )}
          
        </Grid>
        <News />
      </Box>
      {(isMobile || pathName !== "/") && (
        <div style={{ height: "25vh", width: "100%" }}></div>
      )}
    </>
  );
};

export default Header;
