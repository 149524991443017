import React from 'react'
import Container from '@mui/material/Container'
import './Fee.css'
import Fee from "../../../assests/images/fee.jpeg"
import HeadingContainer from '../../heading-container/HeadingContainer'

function Admission() {
    return (
        <>
            <HeadingContainer title="Fee Structure" />
            <Container maxWidth="xl">
                <hr />
                <div className='fee-div'>
                   <img src={Fee} alt="" />
               </div>
            </Container>
        </>
    )
}

export default Admission