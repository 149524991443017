import React, { useState } from "react";
import "./newsletter.css";
import { Card } from "reactstrap";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import CardHeader from "@mui/material/CardHeader";
import { CardContent } from "@mui/material";
import Button from "@mui/material/Button";
import { Snackbar } from "@mui/material";
import HeadingContainer from "../heading-container/HeadingContainer";
import {
 LinearProgress
} from "@mui/material";
import axios from "axios";



const Newsletter = () => {
  const [config, setConfig] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    phoneError: false,
  });

  const [visible, setVisible] = useState(false);
  const [linearLoading, setLinearLoading] = useState(false);


  const handleSubmit = (e) => {
    e.preventDefault();
    setLinearLoading(true);
    if (config.phone.length !== 10) {
      setConfig({ ...config, phoneError: true });
      setLinearLoading(false);
      return;
    }
    setConfig({ ...config, phoneError: false });

    axios
      .post("https://whitewingsschool-backend.vercel.app/users/api/addUser", config, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_PASSWORD}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setVisible(true);
        setLinearLoading(false);
        setConfig({ name: "", email: "", phone: "", message: "" });
      });
  };

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <>
      {" "}
      <HeadingContainer title="Contact Us" />
      {linearLoading && <LinearProgress color="success" />}
      <div className="newsletter" id="contact">
        <form onSubmit={handleSubmit} className="formContainer">
          <Card className="card">
            <CardHeader title="Contact Us" subheader="" />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="outlined-basic"
                    label="Name"
                    fullWidth
                    value={config.name}
                    onChange={(e) => setConfig({ ...config, name: e.target.value })}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    type={"email"}
                    fullWidth
                    value={config.email}
                    onChange={(e) => setConfig({ ...config, email: e.target.value })}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="outlined-basic"
                    label="Phone"
                    type={"number"}
                    error={config.phoneError}
                    fullWidth
                    value={config.phone}
                    onChange={(e) => setConfig({ ...config, phone: e.target.value })}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="outlined-basic"
                    label="Message"
                    fullWidth
                    value={config.message}
                    onChange={(e) => setConfig({ ...config, message: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12} sm={12} className="submitButton">
                  <Button variant="contained" type="submit">
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
        <Snackbar
          style={{ backgroundColor: "green", color: "white" }}
          open={visible}
          autoHideDuration={2000}
          onClose={handleClose}
          message="Thank you for connecting with us"
        />
      </div>
      <hr style={{ color: "white", backgroundColor: "white" }} />
    </>
  );
};

export default Newsletter;
