import React from 'react';
import './news.css';

const InfiniteScrollAnimation = () => {
   
    return (
    <div className="scroll-container">
      <div className="content">
          <div className="item"><ul>
            <li>Admission open for batch 2022-23</li></ul></div>
      
      </div>
    </div>
  );
};

export default InfiniteScrollAnimation;
