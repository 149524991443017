import React from "react";
import LandingPage from "../components/Landing-Page/LandingPage";
import AboutUs from "../components/About-us/AboutUs";
import Newsletter from "../components/Newsletter/Newsletter";
import Mission from "../components/mission-vision/Mission";
import Levels from "../components/levels/Levels";
import Methodology from "../components/methodology/Methodology";
import Management from "../components/management/Management";
import Activity from "../components/Activity/Activity";
import Approaches from "../components/Approaches/Approaches";
import Admission from "../components/Admission/admission-form/Admission";
import FeeStructure from "../components/Admission/fee-structure/FeeStructure";
import Message from "../components/principle mess/Message";
import PageNotFound from "../components/page-not-found/NotFound"
import { Switch, Route } from "react-router-dom";

const Home = () => {
  return (
    <>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route path="/aboutus" component={AboutUs} />
        <Route path="/mission-vision" component={Mission} />
        <Route path="/contact" component={Newsletter} />
        <Route path="/levels" component={Levels} />
        <Route path="/methodology" component={Methodology} />
        <Route path="/management" component={Management} />
        <Route path="/activity" component={Activity} />
        <Route path="/approaches" component={Approaches} />
        <Route path="/admission-form" component={Admission} />
        <Route path="/fee-structure" component={FeeStructure} />
        <Route path="/message" component={Message} />
        <Route exact path="/*" component={PageNotFound} />
      </Switch>
    </>
  );
};

export default Home;
