import React from 'react'
import Under from '../underCon/Under'
function Approaches() {
  return (
    <>
      <Under />
    </>
  )
}

export default Approaches