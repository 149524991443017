import React from 'react';
import Container from '@mui/material/Container';
import { Typography, Paper, Grid } from '@mui/material';
import './levels.css';
import HeadingContainer from "../heading-container/HeadingContainer";

function LevelCard({ level, ageRange, description }) {
    return (
        <Grid item xs={12} sm={6} md={6}>
            <Paper className="levelCard" elevation={3}>
                <Typography variant="h6" className="levelTitle">
                    {level}
                </Typography>
                <Typography variant="subtitle1" className="ageRange">
                    Age range: {ageRange}
                </Typography>
                <Typography variant="body1" className="description">
                    {description}
                </Typography>
            </Paper>
        </Grid>
    );
}

function Levels() {
    const levels = [
        {
            level: "Toddler Program",
            ageRange: "18 months - 2 years",
            description: "The Toddler Programme is a wonderful place for young children to begin their educational and social development in a secure and supportive setting. Our goal is to encourage their natural curiosity, sense of autonomy, and early development through age-appropriate activities, play, and interactive experiences. Our qualified teachers foster a warm and nurturing environment where children can develop to their full potential, laying the groundwork for their future academic success."
        },
        {
            level: "Nursery Program",
            ageRange: "2 - 3 years",
            description: "The Nursery Program provides a secure and supportive setting for children to begin their educational and social development. We encourage their natural curiosity, sense of autonomy, and early development through age-appropriate activities, play, and interactive experiences. Our committed teachers foster a warm and welcoming environment where children can develop to their full potential, preparing them for their future academic journey."
        }
        ,
        {
            level: "Pre-Kindergarten Program",
            ageRange: "3 - 4 years",
            description: "The Pre-Kindergarten Program offers a place where children play, learn to interact with others, and get their first taste of formal education. We focus on encouraging their natural curiosity, social and emotional growth, and the development of essential skills through age-appropriate play, interactive experiences, and other means of learning. Our committed teachers foster a warm and welcoming environment where children can develop to their full potential, preparing them for kindergarten and beyond."
        }
        ,
        {
            level: "Kindergarten Program",
            ageRange: "4 - 5 years",
            description: "In the Kindergarten Program, children begin their educational journey in a secure and encouraging setting. We aim to encourage their natural curiosity, social and emotional growth, and core skill development through age-appropriate activities, play, and engaging experiences. Our committed teachers cultivate a nurturing and welcoming environment in which children can flourish, empowering them to make a smooth transition to primary school and laying the groundwork for lifelong learning."
        }
    ];

    return (
        <div className="levelsContainer">
            <HeadingContainer title="Levels" />
            <Container maxWidth="md" className="contentContainer">
                
                <Grid container spacing={2} justifyContent="center">
                    {levels.map((level, index) => (
                        <LevelCard
                            key={index}
                            level={level.level}
                            ageRange={level.ageRange}
                            description={level.description}
                        />
                    ))}
                </Grid>
            </Container>
        </div>
    );
}

export default Levels;
