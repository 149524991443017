import React from 'react'
import Under from '../underCon/Under'
function Management() {
    return (
        <>
            <Under />
        </>
    )
}

export default Management