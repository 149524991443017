import React from 'react'
import { Button } from '@mui/material'
function ContactDetails({ isMiniMobile, handleClickOpen, isMobile }) {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "600",
                color: "black",
            }}
        >
            {/* Flex Grow Start */}
            <div style={{ flexGrow: 1 }}></div>
            {/* Flex Grow End*/}

            <div style={{ marginLeft: "8px" }}>
                <span style={{ padding: "8px" }}>
                    <i class="ri-phone-line" style={{ color: "blue" }}></i>{" "}
                    <span>+91 98870 28003</span>
                </span>
                {!isMiniMobile && (
                    <>
                        <span style={{ padding: "8px" }}>
                            <i class="ri-phone-line" style={{ color: "blue" }}></i>{" "}
                            <span>+91 82332 77896</span>
                        </span>
                        <span style={{ padding: "8px" }}>
                            <i class="ri-mail-line" style={{ color: "red" }}></i>{" "}
                            <span>whitewings.jaipur@gmail.com</span>
                        </span>
                    </>
                )}
            </div>

            <div
                style={{
                    display: "flex",
                    padding: "8px",
                }}
            >
               
                    <>
                        <span className="headerFbNav">
                            {" "}
                            <a
                                href="https://www.facebook.com/profile.php?id=100089668290442&mibextid=ZbWKwL"
                                style={{ textDecoration: "none", marginRight: "8px" }}
                            >
                                <i
                                    class="ri-facebook-line"
                                    style={{
                                        fontWeight: "600",
                                        color: "white",
                                        fontSize: "20px",
                                    }}
                                ></i>
                            </a>
                        </span>
                        <span className="headerInNav">
                            {" "}
                            <a
                                href="https://www.instagram.com/whitewingsjaipur/"
                                style={{ textDecoration: "none", marginRight: "8px" }}
                            >
                                <i
                                    class="ri-instagram-line"
                                    style={{
                                        fontWeight: "600",
                                        color: "white",
                                        fontSize: "20px",
                                    }}
                                ></i>
                            </a>
                        </span>
                    </>
            
                <Button
                    style={{ background: "#f00", marginRight: "0px" }}
                    variant="contained"
                    onClick={handleClickOpen}
                >
                    Pay Fee
                </Button>
            </div>
        </div>
    )
}

export default ContactDetails