import React from "react";
import "./about.css";
import "./about.css";
import HeadingContainer from "../heading-container/HeadingContainer";
import { Typography } from "@mui/material";
const AboutUs = () => {
  return (
    <>
      {" "}
     <HeadingContainer title="About Us" />
      <section class="mission-wrap padding-60">
        {" "}
        <div class="about_cont">
          {" "}
          <div class="col-sm-9 col-sm-offset-2">
            <h2 class="curve-heading">About us</h2>
            <div class="mission-content">
             
            <Typography variant="body1" style={{ color: "black",textAlign:"justify" }}>
                    We believe that each child is inherently unique in his or
                    her learning capacities and capabilities, so at White Wings,
                    we place a heavy focus on fundamental skills while also
                    striving to identify and meet the specific requirements of
                    each student. We respect the past but know that in the
                    present and future, students need a curriculum that is
                    forward-thinking and contemporary if they are to succeed.
                    The school's reputation for academic achievement stems from
                    its numerous accolades and great teachers. We believe that
                    the flame of consciousness already lit in each child, and
                    the extent to which it may influence their life if
                    nourished, is just as crucial as a rigorous academic
                    program.
                  </Typography>
                
            </div>{" "}
          </div>{" "}
        </div>
      </section>
    </>
  );
};

export default AboutUs;
