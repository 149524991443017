import React from 'react';
import { AiFillHome } from 'react-icons/ai';
import { FaSchool } from 'react-icons/fa';
import { HiAcademicCap } from 'react-icons/hi';
import { IoMdPersonAdd } from 'react-icons/io';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';

const Links = ({ toggleMenu }) => {
  const history = useHistory();

  const dropdownItems = [
    {
      icon: <AiFillHome style={{ marginRight: '6px' }} />,
      label: 'HOME',
      color: '#00afef',
      route: '/',
    },
    {
      icon: <FaSchool style={{ marginRight: '6px' }} />,
      label: 'THE SCHOOL',
      color: '#f58634',
      subItems: [
        { label: 'About Us', route: '/aboutus' },
        { label: 'Mission / Vision', route: '/mission-vision' },
        { label: 'Management', route: '/management' },
        { label: "Principal's Message", route: '/message' },
      ],
    },
    {
      icon: <HiAcademicCap style={{ marginRight: '6px' }} />,
      label: 'ACADEMICS',
      color: '#6f4792',
      subItems: [
        { label: 'Levels', route: '/levels' },
        { label: 'Methodology', route: '/methodology' },
        { label: 'Approaches', route: '/approaches' },
        { label: 'Activity', route: '/activity' },
      ],
    },
    {
      icon: <IoMdPersonAdd style={{ marginRight: '6px' }} />,
      label: 'ADMISSIONS',
      color: '#d35591',
      subItems: [
        { label: 'Admission Form', route: '/admission-form' },
        { label: 'Fee Structure', route: '/fee-structure' },
      ],
    },
    {
      icon: <BsFillTelephoneFill style={{ marginRight: '6px' }} />,
      label: 'CONTACT US',
      color: '#00a859',
      route: '/contact',
    },
  ];

  const handleItemClick = (route) => {
    history.push(route);
    toggleMenu();
  };

  return (
    <>
      {dropdownItems.map((item, index) => (
        <div key={index} className="dropdown">
          <button
            className="dropbtn"
            style={{ color: item.color, fontWeight: 600 }}
            onClick={() => {
              if(item?.subItems) return;
              handleItemClick(item.route);
            }}
          >
            {item.icon}
            {item.label}
          </button>
          {item.subItems && (
            <div className="dropdown-content">
              {item.subItems.map((subItem, subIndex) => (
                <button
                  key={subIndex}
                  className="buttonStyle"
                  onClick={() => handleItemClick(subItem.route)}
                >
                  {subItem.label}
                </button>
              ))}
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default Links;