import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Home from "../assests/icon/Home.svg"
import Phone from "../assests/icon/Phone.svg"
import Admission from "../assests/icon/Admission.svg"
import { useHistory } from 'react-router-dom';
export default function BottomNav() {
    const history = useHistory();
    const [value, setValue] = React.useState('Home');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <BottomNavigation style={{ position: "fixed", bottom: "0px", width: "100%", backgroundColor: "#198754",zIndex:9999 }} value={value} onChange={handleChange}>
            <BottomNavigationAction
                label="Admission"
                value="Admission"
                icon={<img src={Admission} alt="" height={20}/>}
                style={{ color: "white" }}
                onClick={()=>history.push("/admission-form")}
            />
            <BottomNavigationAction
                label="Home"
                value="Home"
                style={{color:"white"}}
                icon={<img src={Home} alt="" height={20}/>}
                onClick={()=>history.push("/")}
            />
            <BottomNavigationAction
                label="Contact"
                value="Contact"
                style={{color:"white"}}
                icon={<img src={Phone} alt="" height={20}/>}
                onClick={()=>history.push("/contact")}
            />
        </BottomNavigation>
    );
}