import React from 'react'
import Typography from '@mui/material/Typography'
import './headingContainer.css'
function HeadingContainer(props) {
    return (
        <div className='headingContainer' style={{display:"flex",alignItems:"center",justifyContent:"center",height:"20vh",width:"100%",backgroundColor:"black"}}>
            <Typography variant="h4" style={{ color: "white" }}>
                {props?.title}
            </Typography>
        </div>
    )
}

export default HeadingContainer