import React from 'react';
import Container from '@mui/material/Container';
import { Typography, Paper } from '@mui/material';
import './methodology.css';
import HeadingContainer from '../heading-container/HeadingContainer';

function Methodology() {
  return (
    <div className="methodologyContainer">
      <HeadingContainer title="Methodology" />
      <Container maxWidth="md" className="contentContainer" component={Paper}>
        <Typography variant="h4" className="methodologyTitle">
          Methodology:
        </Typography>
        <Typography variant="body1" className="introduction">
          We are dedicated to providing a comprehensive and holistic educational experience for young learners. This methodology content will outline our approach to teaching and learning, ensuring that children develop the necessary skills and knowledge while having fun and fostering a love for learning.
        </Typography>
        <br />
        <Typography variant="h6" style={{ color: "black" }}>
                    Play-Based Learning:
                </Typography>
                <br />
                <Typography variant="body1" style={{ color: "black",textAlign:"justify" }}>
                    At White Wings, we believe that play is an essential component of a child's development. Our curriculum incorporates play-based learning activities to engage children in hands-on experiences that promote creativity, imagination, problem-solving, and social interaction. Through play, children learn important skills, such as communication, collaboration, critical thinking, and self-regulation.
                </Typography>
                <br />
                <Typography variant="h6" style={{ color: "black" }}>
                    Child-Centred Approach:
                </Typography>
                <br />
                <Typography variant="body1" style={{ color: "black",textAlign:"justify" }}>
                    Our methodology revolves around the needs and interests of each individual child. We recognize that children have different learning styles and paces, and we strive to create an inclusive environment where all children feel valued and supported. Our teachers observe and assess each child's progress, tailoring the learning experiences to meet their specific needs and abilities.
                </Typography>
                <br />
                <Typography variant="h6" style={{ color: "black" }}>
                    Multisensory Learning:
                </Typography>
                <br />
                <Typography variant="body1" style={{ color: "black",textAlign:"justify" }}>
                    To cater to diverse learning styles, we employ a multisensory approach to teaching. Our lessons integrate visual, auditory, and kinesthetic elements to engage children's senses and enhance their understanding of concepts. By incorporating hands-on activities, music, movement, storytelling, and visual aids, we ensure that children are actively involved in their learning journey.
                </Typography>
                <br />
                <Typography variant="h6" style={{ color: "black" }}>
                    Playful Assessment:
                </Typography>
                <br />
                <Typography variant="body1" style={{ color: "black",textAlign:"justify" }}>
                    Assessment in the pre-primary stage is conducted in a playful and non-threatening manner. We use observation, anecdotal records, portfolios, and informal assessments to gather evidence of children's progress and understanding. This approach allows teachers to evaluate each child's development across various domains, including cognitive, social, emotional, and physical, while providing ongoing feedback to parents.
                </Typography>
                <br />
                <Typography variant="h6" style={{ color: "black" }}>
                    Language and Literacy Development:
                </Typography>
                <br />
                <Typography variant="body1" style={{ color: "black",textAlign:"justify" }}>
                    We place a strong emphasis on language and literacy development. Our curriculum incorporates a range of activities that promote vocabulary expansion, phonemic awareness, pre-reading skills, and oral communication. Storytelling, rhymes, interactive reading, and dramatic play are integrated into daily routines to foster a love for language and support children's emergent literacy skills.
                </Typography>
                <br />
                <Typography variant="h6" style={{ color: "black" }}>
                    Social and Emotional Learning (SEL):
                </Typography>
                <br />
                <Typography variant="body1" style={{ color: "black",textAlign:"justify" }}>
                    At our pre-primary school, we recognize the importance of nurturing children's social and emotional well-being. We prioritize creating a positive and inclusive classroom environment where children feel safe, respected, and supported. Our curriculum includes activities that promote self-awareness, self-regulation, empathy, and relationship-building skills to help children develop strong social and emotional foundations.
                </Typography>
                <br />
                <Typography variant="h6" style={{ color: "black" }}>
                    STEM Education:
                </Typography>
                <br />
                <Typography variant="body1" style={{ color: "black",textAlign:"justify" }}>
                    Introducing basic concepts of Science, Technology, Engineering, and Mathematics (STEM) at an early age fosters curiosity and critical thinking skills. We integrate STEM activities into our curriculum to encourage children to explore and investigate the world around them. Through hands-on experiments, building structures, problem-solving challenges, and logical reasoning tasks, we spark their interest in STEM subjects.
                </Typography>
                <br />
                <Typography variant="h6" style={{ color: "black" }}>
                    Conclusion:
                </Typography>
        <br />
        <Typography variant="body1" className="conclusion">
          At White Wings, Our methodology for pre-primary education centers around play-based learning, child-centered approaches, multisensory experiences, playful assessment, language and literacy development, social and emotional learning, and early exposure to STEM education. By embracing these principles, we aim to provide a nurturing and stimulating environment that empowers young learners to thrive and develop a lifelong love for learning.
        </Typography>
        <br />
      </Container>
      <div style={{ height: "5vh" }}></div>
    </div>
  );
}

export default Methodology;
