import React from 'react'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { Box, Grid, Paper } from '@mui/material'
import { useHistory } from 'react-router-dom';
import { BsFillArrowRightCircleFill } from 'react-icons/bs'
function Welcome() {
    const history = useHistory();
    const events = [
        {
            date: "26",
            month: "Jan",
            content: "",
        },
        {
            date: "15",
            month: "Oct",
            content: "",
        },
        {
            date: "18",
            month: "Oct",
            content: "",
        },
        {
            date: "",
            month: "",
            content: "",
        },
        {
            date: "",
            month: "",
            content: "",
        }
    ]
    return (
        <>
            <Container maxWidth="xl" style={{ margin: "20px 0px" }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={8}>

                        <Box
                            margin='20px'
                        >
                            <div className='headingUnderline'><Typography variant="h4" style={{ color: "green", fontWeight: "600" }}>Welcome To White Wings School</Typography></div>
                            <br />
                            <Typography variant="body1" style={{ color: "black", fontWeight: "600", textAlign: "justify" }}>The first biggest dream of Rajkumari Education Society turned into a vibrant reality when White Wings Pre-School, Jaipur was formally inaugurated on January 22, 2023.
                                We are delighted to have you visit our virtual home, where curiosity and creativity come alive! At White Wings Pre-School, we believe that every child is a unique individual, brimming with potential and wonder. Our preschool is dedicated to providing a nurturing and stimulating environment, where children can embark on a journey of joyful learning and discovery.
                            </Typography>

                            <br />
                            <Typography variant="body1" style={{ color: "black", fontWeight: "600", textAlign: "justify" }}>
                                Thank you for visiting us today. We look forward to the possibility of welcoming your child to our preschool family and embarking on an incredible journey of growth, friendship, and boundless possibilities.
                            </Typography>
                            <br /><br />
                            <Typography variant="body1" style={{ color: "black", fontWeight: "600" }}>
                                Warm regards,
                            </Typography>
                            <br />
                            <Typography
                                variant="h5"
                                style={{
                                    color: "black",
                                    fontWeight: "600",
                                    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
                                }}
                            >
                                <span style={{ color: "#FF0000" }}>W</span>
                                <span style={{ color: "#FFA500" }}>h</span>
                                <span style={{ color: "blue" }}>i</span>
                                <span style={{ color: "#00FF00" }}>t</span>
                                <span style={{ color: "#EE82EE" }}>e </span>
                                <span style={{ color: "#orange" }}>W</span>
                                <span style={{ color: "#EE82EE" }}>i</span>
                                <span style={{ color: "#FF0000" }}>n</span>
                                <span style={{ color: "#FFA500" }}>g</span>
                                <span style={{ color: "blue" }}>s</span>

                                <span style={{ color: "green" }}> School</span>{" "}
                            </Typography>
                            <br />
                            <button onClick={() => history.push("./aboutus")} >
                                <div style={{ display: "flex" }}>
                                    <div style={{ backgroundColor: "white", padding: "2px" }}>
                                        <Typography variant="h6" style={{ color: "red" }}>Read More</Typography>
                                    </div>
                                    <div style={{ padding: "2px", width: "30px", backgroundColor: "red" }}>
                                        <BsFillArrowRightCircleFill style={{ color: "white" }} />
                                    </div>
                                </div>
                            </button>
                        </Box>
                        <br />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Paper elevation={0} style={{ padding: "20px" }}>
                            <Typography variant="h4" style={{ paddingBottom: "10px" }}>
                                Events
                            </Typography>
                            <Grid container spacing={3}>

                                {events.map((event) => (
                                    <Grid item xs={12} sm={12}>
                                       <Grid container spacing={0}>
                                            <Grid item xs={2} sm={2}>
                                                <div class={event.date === "" ? '': "widget-line"}  style={{ display: 'flex', flexDirection: 'column'}}>
                                                    <div style={{color:"#388753"}}>{event.date}</div>
                                                    <div >{event.month}</div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={10} sm={10}>
                                                <Typography variant="body1" style={{ color: "#388753" }}>{event.content}</Typography>
                                            </Grid>
                                       </Grid>
                                    </Grid>
                                ))
                                }

                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>

            </Container>
            <div style={{ height: "5vh" }}></div>
        </>
    )
}

export default Welcome