import React from "react";
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import Logo from "../../assests/images/wLogo.jpeg";
import "./footer.css";
import { useHistory } from "react-router-dom";

const footerQuickLinks = [
  {
    display: "Home",
    url: "/",
  },
  {
    display: "About US",
    url: "/aboutus",
  },

  {
    display: "Contact",
    url: "/contact",
  },
];

const Footer = () => {
  const history = useHistory();
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg="4" md="8" className="mb-4">
            <div
              style={{ display: "flex", gap: "8px", flexDirection: "column" }}
            >
              <img src={Logo} alt="logo" height="100px" width="100px" />
              <span
                style={{
                  marginLeft: "8px",
                  color: "white",
                  fontSize: "36px",
                  fontWeight: 700,
                  fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
                  textTransform: "uppercase",
                }}
              >
                <span style={{ color: "#FF0000" }}>W</span>
                <span style={{ color: "#FFA500" }}>h</span>
                <span style={{ color: "#FFFF00" }}>i</span>
                <span style={{ color: "#00FF00" }}>t</span>
                <span style={{ color: "#EE82EE" }}>e</span>{" "}
                <span style={{ color: "#orange" }}>W</span>
                <span style={{ color: "#EE82EE" }}>i</span>
                <span style={{ color: "#FF0000" }}>n</span>
                <span style={{ color: "#FFA500" }}>g</span>
                <span style={{ color: "#FFFF00" }}>s</span>
              </span>
            </div>
          </Col>

          <Col lg="4" md="8" className="mb-4">
            <h3 class="widget-title">Explore</h3>
            <ListGroup className="link__list">
              {footerQuickLinks.map((item, index) => (
                <ListGroupItem key={index} className="border-0 ps-0 link__item">
                  {" "}
                  <a href={item.url}>{item.display}</a>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>

          <Col lg="4" md="8" className="footerDis">
            <h3 class="widget-title">Contact Us</h3>
            <p>White Wings School</p>
            <p>Roshan Nagar-B Kanakpura </p>
            <p>Sirsi Road Near Balaji Hospital</p>
            <p>Jaipur {`(Raj) 302034`}</p>
            <p>Mob/ WhatsApp: 9887028003</p>
            <p>whitewings.jaipur@gmail.com</p>
            <div className="footerNavLink">
              <a href="https://www.facebook.com/profile.php?id=100089668290442&mibextid=ZbWKwL">
                <button
                  className="iconButton"
                  onClick={() => history.push("/")}
                >
                  <i style={{ color: "white" }} class="ri-facebook-line"></i>
                </button>
              </a>
              <button className="iconButton" onClick={() => history.push("/")}>
                <i style={{ color: "white" }} className="ri-twitter-line"></i>
              </button>
              <button className="iconButton" onClick={() => history.push("/")}>
                <i style={{ color: "white" }} className="ri-youtube-line"></i>
              </button>
              <a href="https://www.instagram.com/whitewingsjaipur/">
                {" "}
                <button className="iconButton" id="instagramIcon" onClick={() => history.push("")}>
                  <i
                    style={{ color: "white" }}
                    className="ri-instagram-line"
                  ></i>
                </button>{" "}
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
