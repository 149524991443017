import Home from "./pages/Home";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import BottomNav from "./pages/BottomNav";
import { useState,useEffect } from "react";
function App() {
  const [isMobile, setIsMobile] = useState(false);
  const [isMiniMobile, setIsMiniMobile] = useState(false);
  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 968);
      setIsMiniMobile(window.innerWidth < 476);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return <>
    <Header />
    {(isMobile || isMiniMobile) && <BottomNav />} 
    <Home />
    <Footer />
  </>;
}

export default App;
