import React from 'react'
import './mission.css'
import HeadingContainer from '../heading-container/HeadingContainer'
import { Typography } from '@mui/material'
function Mission() {
    return (
        <>
            {" "}
            <HeadingContainer title="Mission & Vision" />
            <section class="mission-wrap padding-60"> <div class="cont"> <div class="col-sm-9 col-sm-offset-2">
                <h2 class="curve-heading">Mission</h2>
                <div class="mission-content">
                    <h2>"It is easier to follow the leader than to lead the follower.”</h2>

                    <Typography variant="body1" style={{ color: "black",textAlign:"justify" }}>To foster the growth of the whole child via a rigorous Montessori education that emphasizes the cultivation of critical thinking, a solid academic foundation, social awareness, empathy, and self-assurance.Instilling in a child the mental, emotional, and physical toughness necessary for them to develop into an independent learner, open-minded thinker, resourceful problem-solver, and explorer of the world around them.
                            Inspiring our kids to become responsible, engaged citizens of the world.</Typography>
                   
                </div> </div> </div>
            </section>
            <section class="mission-wrap padding-60"> <div class="cont"> <div class="col-sm-9 col-sm-offset-2">
                <h2 class="curve-heading">Vision</h2>
                <div class="mission-content">
                    <h2>“Education's purpose is to replace an empty mind with an open one.”</h2>

                    <Typography variant="body1" style={{ color: "black",textAlign:"justify" }}>Our objective is to provide a comprehensive Montessori programme that takes into account the intellectual, emotional, physical, and social development of the whole child. We do this by addressing the individuality of each aspect of development within an atmosphere that is both caring and emotionally secure while also being intellectually good.</Typography>
                   
                </div> </div> </div>
            </section>
        </>
    )
}

export default Mission